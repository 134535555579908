import React from "react";

const Sitemap = () => {
  const sitemap = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  <url>
    <loc>https://creditmitra.in/</loc>
    <changefreq>daily</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/products</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/responsibleLending</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/contact</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/about</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/ourLendingPartners</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/faq</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/grievanceredressal</loc>
    <changefreq>yearly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/privacypolicy</loc>
    <changefreq>yearly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/termsAndConditions</loc>
    <changefreq>yearly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs</loc>
    <changefreq>daily</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/8-tips-to-improve-your-cibil-score</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/improving-your-cibil-score-is-not-rocket-science</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/understanding-personal-loan-recovery</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/personal-loans-vs-credit-cards</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/how-to-get-an-instant-loans-with-low-cibil-score</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/avoid-these-mistakes-while-applying-for-personal-loans</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/negotiating-lower-interest-rates-on-personal-loans</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/blogs/how-does-a-personal-loan-impact-tax-filing</loc>
    <changefreq>monthly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/emergency-loan</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/instant-personal-loan-online</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/medical-loan</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/personal-loan-for-salaried</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/travel-loan</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/marriage-loan</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/personal-loan-emi-calculator</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/sitemap.xml</loc>
    <changefreq>yearly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/instant-personal-loan-hyderabad</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/instant-personal-loan-Bangalore</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/instant-personal-loan-mumbai</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/we-are-hiring</loc>
    <changefreq>weekly</changefreq>
  </url>
  <url>
    <loc>https://creditmitra.in/404</loc>
    <changefreq>yearly</changefreq>
  </url>
</urlset>`;

  return (
    <div>
      <pre>{sitemap}</pre>
    </div>
  );
};

export default Sitemap;
